import Swiper from "swiper";
import { Pagination, Zoom, Autoplay, EffectFade } from "swiper/modules";
import Marquee3k from "marquee3000";
import L from 'leaflet';

import Accordion from "./modules/accordion";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const speakers = document.querySelectorAll('.speaker');
  const bioCloses = document.querySelectorAll('.bio__close');
  const locations = document.querySelectorAll('.event__map');

  function handleDOMContentLoaded() {
    new Swiper('.slider', {
      modules: [ Autoplay, EffectFade ],
      centeredSlides: true,
      effect: 'fade',
      autoplay: {
        delay: 8000,
        disableOnInteraction: false
      },
      loop: true
    });

    new Swiper('.group__slider', {
      modules: [ Pagination, Zoom ],
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      zoom: true
    });

    new Swiper('.speakers', {
      spaceBetween: 21,
      slidesPerView: 'auto'
    });

    Marquee3k.init();

    speakers.forEach((el) => el.addEventListener('click', handleSpeakerBio));
    bioCloses.forEach((el) => el.addEventListener('click', handleBioClose));

    new Accordion();

    const icon = L.icon({
      iconUrl: '/assets/images/pin.svg',
      iconSize: [36, 46],
      iconAnchor: [18, 46],
      popupAnchor: [0, -50]
    });

    locations.forEach((el) => {
      const lat = el.dataset.latitude;
      const lng = el.dataset.longitude;
      const balloonTitle = el.dataset.title;
      const balloonDescription = el.dataset.description;

      const map = L.map(el, {
        scrollWheelZoom: false,
        dragging: true,
        tap: true,
        zoomControl: false
      }).setView([lat, lng], 18);

      L.control.zoom({
        position: 'bottomright'
      }).addTo(map);
      L.tileLayer('https://api.mapbox.com/styles/v1/techgioppy/clpif0z3q00o801pohr9yazix/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: '<a href="https://www.mapbox.com/">Mapbox</a>',
        minZoom: 4,
        maxZoom: 18,
        id: 'mapbox.streets',
        accessToken: 'pk.eyJ1IjoidGVjaGdpb3BweSIsImEiOiJjbHFwM2htZmcwdXZiMnFzYnhjMWkweGExIn0.oPjnoDRIbOYrRXIeLY-tgg'
      }).addTo(map);

      const marker = L.marker([lat, lng], { icon: icon });
      const content = `<div class="balloon"><p><strong>${balloonTitle}</strong></p><p>${balloonDescription}</p></div>`;
      marker.bindPopup(content);
      map.addLayer(marker);
      marker.openPopup();
    });
  }

  function handleSpeakerBio(e) {
    e.preventDefault();
    document.querySelector('.speaker--active')?.classList.remove('speaker--active');
    e.currentTarget.classList.add('speaker--active');

    const speakerId = e.currentTarget.dataset.speaker;

    document.querySelector('.bio--active')?.classList.remove('bio--active');
    document.querySelector(`.bio[data-bio='${speakerId}']`).classList.add('bio--active');
  }

  function handleBioClose(e) {
    const parent = e.currentTarget.parentElement.parentElement;
    const speakerId = parent.dataset.bio;
    parent.classList.remove('bio--active');
    document.querySelector(`.speaker[data-speaker='${speakerId}']`).classList.remove('speaker--active');
  }
})();
